import $ from 'jquery';
import slick from 'slick-carousel';

export default {

	init() {

		var container = document.querySelector('section.MovieSection ');
		var slidesVideos = container.querySelectorAll('.carousel__item:not(.slick-cloned) video');

		// -- carousel -- //

			var carousel = container.querySelector('.MovieSection__carousel');
			var carouselSwipeDirection = null;

			if( carousel ) {

				$(carousel).slick({
					slidesToShow: 1,
					slidesToScroll: 1,
					arrows: false,
					dots: false,
					rows: false,
					adaptiveHeight: true,
				});

			}

			var carouselPromise = null;
			$(carousel).on('afterChange', function( event, slick, currentSlide ) {

				container.querySelectorAll('.carousel__item video').forEach( function( video,index ) {

					if( video.getAttribute('autoplay') ) {

						video.removeAttribute('autoplay');

					}

					if( !video.paused || video.ended ) {

						video.pause();
						video.currentTime = 0;

					}

				});

				carouselPromise = slidesVideos[currentSlide].play();
					
				if( carouselPromise !== undefined ) {

					carouselPromise.then(function() {

						// console.log('Video '+ currentSlide +' play');

					}).catch(function(error) {

						slidesVideos[currentSlide].play();

					});
				}

			});

			slidesVideos.forEach( function( video,index ) {

				video.addEventListener('ended', function() {

					$(carousel).slick('slickNext');

				});

			});

		// -- END -- //


		// -- player -- //
			
			var body = document.querySelector('body');
			var header = body.querySelector('header');

			var buttons = container.querySelectorAll('.playFullMovie');
			var player = container.querySelector('.MovieSection__player');
			var videos = player.querySelectorAll('video');

			var currentMovieKey = 0;

			var isFullscreen = false;
			function closePlayer() {

				isFullscreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;

				if( !isFullscreen ) {

					container.classList.remove('showVideo');

					videos.forEach( function( video, index ) {

						video.classList.remove('show');
						video.pause();
						video.currentTime = 0;

					});

					body.classList.remove('disableScroll');
					header.classList.remove('hide');

					slidesVideos[currentMovieKey].play();

				}

			}

			buttons.forEach( function( button, index ) {

				button.addEventListener('click', function() {

					currentMovieKey = index-1;

					slidesVideos[currentMovieKey].pause();

					container.classList.add('showVideo');

					videos[index-1].classList.add('show');

					body.classList.add('disableScroll');
					header.classList.add('hide');

					// -- open in fullscreen -- //
						if( videos[currentMovieKey].requestFullscreen ) { // opera, chrome, firefox
						    videos[currentMovieKey].requestFullscreen();
						    // alert('01');
						} 
						else if( videos[currentMovieKey].msRequestFullscreen ) {
						    videos[currentMovieKey].msRequestFullscreen();
						    // alert('02');
						}
						else if( videos[currentMovieKey].mozRequestFullScreen ) {
						    videos[currentMovieKey].mozRequestFullScreen();
						    // alert('03');
						}
						else if( videos[currentMovieKey].webkitRequestFullScreen ) { // safari
							videos[currentMovieKey].webkitRequestFullScreen();
						    // alert('04');
						}
					// -- END -- //

					if( videos[currentMovieKey].currentTime >= 0 && videos[currentMovieKey].paused && !videos[currentMovieKey].ended && videos[currentMovieKey].readyState > 2 )  {
						videos[currentMovieKey].play();
					} else {
						videos[currentMovieKey].setAttribute('autoplay','autoplay');
					}

				});

			});

			document.addEventListener('keyup', function(e) {

				if( e.key == 'Escape' ) {

					closePlayer();

				}

			})

			videos.forEach( function( video,index ) {
				// fire when user disable fullscreen on movie under mobileMode window inner width

				// -- exit from fullscreen -- //

					video.addEventListener('webkitendfullscreen', function() { // ipad

						closePlayer();

					});

				// -- END -- //

				// -- change fullscreen -- //

					video.addEventListener('webkitfullscreenchange', function() { // safari

						closePlayer();

					});

					video.addEventListener('mozfullscreenchange', function() {

						closePlayer();

					});

					video.addEventListener('fullscreenchange', function(e) { // chrome

						closePlayer();

					});

					video.addEventListener('MSFullscreenChange', function(e) {
						
						closePlayer();

					});

				// -- END -- //
				
			});
			
		// -- END -- //

	},
	finalize() {

	}

}